
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react'

// React.Component
export function Home(props){
    const [slideNum, setslideNum] = useState( (props.$.arraySerch(props.setting,'main_visual').length)-1 );
    useEffect(() => {
        const intervalId = setInterval(()=>{
            if(slideNum === 0){
                clearInterval(intervalId);
                setslideNum( (props.$.arraySerch(props.setting,'main_visual').length)-1 );
            }else{
                setslideNum(slideNum - 1);
            }
        }, 5000);
        return ()=>{clearInterval(intervalId);};
      }, [slideNum]);
    // 初回起動確認
    if(props.$.arraySerch(props.setting,'regist') != 0){
        return(
            <>
                {/* スライドショー */}
                <div className='w-100 h-100 position-relative'>
                {
                    props.$.arraySerch(props.setting,'main_visual').map((val,index)=>{
                        return(
                            <div key={index} className={`${index === slideNum ? "slideShowFadeIn" : "slideShowFadeOut"} ${index} w-100 h-100 position-absolute`}>
                                <div className='w-100 h-100 position-relative'>
                                    <img id="mainBkImg" src={val['src']} alt="" className="object-cover object-t" />
                                    <div className="mainVisualText position-absolute w-100 top-0 d-flex align-items-center">
                                        <div className="px-3 fw-bold">
                                            <p className="fs-4">{val['subText']}</p>
                                            <p className="fs-7">{val['mainText']}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>

                <div id="loopMenu" className="w-100 position-absolute bottom-0 card-bg overflow-auto">
                    <LoopMenu $={props.$} setting={props.setting} />
                </div>
            </>
        );
    }else{
        return(
            <Regist />
        )
    }
};

function LoopMenu(props){
    return(
        <div className="d-flex flex-wrap pt-3 pb-5 px-2">
        {
            props.$.arraySerch(props.setting,'button').map((val,index)=>{
                return(
                    <div className="card-box" key={index} onClick={ ()=>{props.$.card(val.body)} } >
                        <div className="p-2">
                            <div className="d-flex align-items-center justify-content-center cardbtn shadow">{val.title}</div>
                        </div>
                    </div>
                )
            })
        }
        </div>
    )
};
export function Iflame(props){
    return(
        <div className="h-100">
            <iframe src={props.url} className='w-100 h-100 border-0'></iframe>
        </div>
    );
};

export function Logo(props){
    return(   
        <div className="h-100 px-2 d-flex justify-content-between align-items-center" onClick={()=>props.$.stopScan()}>
            <Link className="logo" to="/">
                <img src={props.$.arraySerch(props.setting,'logo')} alt="ロゴ" className='h-100' />
            </Link>
        </div>
    )
};

export function SideMenu(props){
    const [active, setActive] = useState(false);
    function sideMenu(){ setActive(!active) };
    return(
        <nav id='sideMenu' className={`position-fixed top-0 w-100 vh-100 ${active ? "active" : ""}`}>
            <div id='open' className='sideMenuOpenbtn' onClick={()=>{sideMenu()}}><span></span><span></span><span></span></div>
            <ul className='position-absolute top-50 start-50 translate-middle'>
                {
                props.$.arraySerch(props.setting,'side_menu').map((val,index)=>{
                    return(
                        <li className="p-2 text-center" key={index} onClick={()=>{sideMenu(); props.$.stopScan();}}><Link to={"/"+val.id}>{val.title}</Link></li>
                    )
                })
                }
            </ul>
        </nav>
    )
};

export function QrCode(props){
    props.$.startScan();
    return(   
        <div className="QRScan w-100 h-100 position-relative">
            <div className='w-100 h-100 d-flex'>
                <div className='flamePart side h-100'></div>
                <div className='flamePart center h-100 d-flex flex-column'>
                    <div className='top'></div>
                    <div className='center'></div>
                    <div className='end flex-fill'></div>
                </div>
                <div className='flamePart side h-100'></div>
            </div>
            <div className="position-absolute text w-100 text-center">
                枠内に読取りたいQRコードを収めて下さい。
            </div>
        </div>
    )
    
};

export function LowerMenu(props){
    return(
        <div id="lowerMenu">
            <div className="header">
                <div className="p-2">
                    <div className="closeBtn" onClick={()=>{props.$.cardClose()}}>
                        <img src="./common/img/tarnback.svg" alt="" className="object-cover" />
                    </div>
                </div>
            </div>
            <div id="changeTitle" className="p-3"></div>
            <div className="p-3">
                <img id="changeImg" className="w-100" src="" />
            </div>
            <div id="changeText" className="p-3"></div>
        </div>
    )
};

export function Footer(props){
    let i = 0;
    return(
        <div id="footer" className="position-fixed bottom-0 start-0 w-100 shadow-lg">
            <div className="p-2 d-flex justify-content-between align-items-center text-center">
                {
                    props.$.arraySerch(props.setting,'footer').map((val,index)=>{
                        const renderHtml = [];
                        renderHtml.push(
                            <div key={i} className="footerContent">
                                <a href={val.link} className={val.link==''?'pe-none':''}>
                                    <div className="imgBox mx-auto">
                                        <img src={val.src} alt={val.title} className="w-100" />
                                    </div>
                                    <div className="fs-7 pt-1">{val.title}</div>
                                </a>
                            </div>
                        );
                        // 真ん中にQRコードを追加
                        if(index == 1){ i++; renderHtml.push(
                            <div key={i} className="footerContentCenter position-relative">
                                <div id="QRcodeOn" className="position-absolute bottom-0 start-50 w-75 rounded-circle" onClick={()=>props.$.qrCodeApp()}>
                                    <img src="./common/img/qr.svg" alt="" />
                                </div>
                            </div>
                        );}
                        i++;
                        return renderHtml;
                    })
                }
            </div>
        </div>
    )
};

export function Regist(){
    return(
        <h1>Reg</h1>
    )
}