
import { Device } from "@capacitor/device";
const device = await Device.getId();
const deviceId = device.identifier;

export const firstData = [
    // 登録FLG
    {name:"regist",value:"1"},
    // デバイスID
    {name:"device",value:deviceId},
    // バージョン
    {name:"version",value:"1970010101"},
    // ロゴ
    {name:"logo",value:"./common/img/logo.png"},
    // メインビジュアル
    {name:"main_visual",value:[
        {"id":1,"src":"./common/img/main01.jpg","mainText":"あなたのビジネスにベストなICTソリューションを提案","subText":"Providing the Best Possible ICT Solutions"},
        {"id":2,"src":"./common/img/main02.jpg","mainText":"あなたのビジネスにベストなICTソリューションを提案","subText":"slide2"},
        {"id":3,"src":"./common/img/main03.jpg","mainText":"","subText":""}
    ]},
    // フッター
    {name:"footer",value:[
        {"id":1,"title":"電話","src":"./common/img/tel.webp","link":"tel:050-3591-8322"},
        {"id":2,"title":"メール","src":"./common/img/mail.webp","link":"mailto:info@intec-sol.co.jp"},
        {"id":3,"title":"Instagram","src":"./common/img/Instagram-icon.webp","link":""},
        {"id":4,"title":"Facebook","src":"./common/img/facebook-icon.webp","link":""},
    ]},
    // {name:"button",value:[
    //     {"id":1,"title":"タイトル1","body":"<div id='body1'><P id='bbb'>本文１</p></div>","img":"./common/img/main_visual.jpg"},
    //     {"id":2,"title":"タイトル2","body":"本文２","img":"./common/img/main_visual.jpg"},
    //     {"id":3,"title":"タイトル3","body":"本文３","img":"./common/img/main_visual.jpg"},
    //     {"id":4,"title":"タイトル4","body":"本文４","img":"./common/img/main_visual.jpg"},
    //     {"id":5,"title":"タイトル5","body":"本文５","img":"./common/img/main_visual.jpg"},
    //     {"id":6,"title":"タイトル6","body":"本文６","img":"./common/img/main_visual.jpg"},
    // ]},
    {name:"button",value:[
        // {"id":1,"title":"\u30bf\u30a4\u30c8\u30eb1","body":"<script>console.log('\u95a2\u6570')<\/script>","img":""}
        // {"id":1,"title":"\u30bf\u30a4\u30c8\u30eb1","body":"<p>test<p><script>console.log('\u95a2\u6570');fetch('https:\/\/aws16.intec-sol.co.jp\/api\/version',{method: 'POST'}).then((res)=>{return res.json()}).then((newVer)=>{console.log(newVer);});<\/script>","img":""}
        // {"id":1,"title":"\u30bf\u30a4\u30c8\u30eb1","body":"<p id='memberCard'><\/p><script>console.log('\u95a2\u6570\u5b9f\u884c');fetch('https:\/\/aws16.intec-sol.co.jp\/api\/version',{method: 'POST'}).then((res)=>{return res.json()}).then((newVer)=>{document.getElementById('changeText').innerHTML=newVer;});<\/script>","img":""}
        // {"id":1,"title":"\u30bf\u30a4\u30c8\u30eb1","body":"<button id='testBtn' type='button' onclick='buttonClick()'>\u30dc\u30bf\u30f3<\/button><script>console.log('\u95a2\u6570\u5b9f\u884c');function buttonClick(){alert('Click');}<\/script>","img":""}
        {"id":1,"title":"\u30bf\u30a4\u30c8\u30eb1","body":"<button id='testBtn' type='button' onclick='buttonClick()'>\u30dc\u30bf\u30f3<\/button><input id='pageInputVal' type='text' \/><script>console.log('\u95a2\u6570\u5b9f\u884c');function buttonClick(){alert(document.getElementById('pageInputVal').value);}<\/script>","img":""},
        {"id":2,"title":"\u30bf\u30a4\u30c8\u30eb1","body":"<button id='testBtn' type='button' onclick='buttonClick()'>\u30dc\u30bf\u30f3<\/button><script>console.log('csrf_token');function buttonClick(){alert(document.getElementById('csrf-token').getAttribute('content'));}<\/script>","img":""},
        {"id":3,"title":"\u30bf\u30a4\u30c8\u30eb1","body":"<script>window.location.href = '/qrcode';<\/script>","img":""}
    ]},

    {name:"side_menu",value:[
        {"id":1,"title":"インテック","url":"https://intec-sol.co.jp"},
        {"id":2,"title":"福実会","url":"https://fukumikai.or.jp"},
        {"id":3,"title":"プラスボディ","url":"https://plusbody.jp"},
        {"id":4,"title":"プラスボディ","url":"https://plusbody.jp"}

    ]},
]
