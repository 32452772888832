import $ from 'jquery';
import Dexie from 'dexie';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';


/*==================================
    For React
==================================*/
export function ver(){
    console.log($.fn.jquery);
}
// 下層ページを開く
export function card(html){
    $('#changeText').html(html);
    $('#lowerMenu').css('transition', 'all 0.6s');
    $('#lowerMenu').addClass('active');
}
// 下層ページを閉じる
export function cardClose(){
    $('#lowerMenu').removeClass('active');
};




// indexedbから値を取得
export function arraySerch(array,key){
    let elem = array.find(n => n.name === key);
    return elem.value;
}
// JSON判定
export function isJson(data) {
    try {
        JSON.parse(data);
    } catch (error) {
        return false;
    }
    return true;
};




/*==================================
    For IndexedDB(Dexie)
==================================*/

export class AppDexie extends Dexie {

    constructor(dataBase,dbVersion,stores,store){
        super(dataBase);
        this.version(dbVersion).stores(stores);
        this.open().catch((error)=>{ alert(error); });
        this.store = this.table(store);
    }

    async step1(data){
        const count = await this.store.count().then((count)=>{return count});
        if(count === 0){ await this.store.bulkPut(data); };
        // indexedb データ取得
        const setting = this.toArray();
        return setting;
    }

    bulkPut(data){
        this.transaction('rw',this.store,()=>{
            this.store.bulkPut(data);
        })
        .then(()=>{})
        .catch((error)=>{ console.log(error)})
    }
    async toArray(){
        const array = await this.store.toArray().then((data)=>{return data;});
        return array;
    }


}



/*==================================
    For Capacitor(QRCode)
==================================*/
export function qrCodeApp(){
    let url = window.location.pathname;
    if(url == '/qrcode'){
        stopScan();
        window.history.back();
    }else{
        window.location.href = '/qrcode';
    }
}
export async function startScan(){
    BarcodeScanner.hideBackground();
    const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] });
    if (result.hasContent){
        if(isJson(result.content)){
            let res = JSON.parse(result.content);
            alert(res.url);
            stopScan();
            window.history.back();
        }else{
            alert('無効');
            stopScan();
            window.history.back();
        }
    }
};
export function stopScan(){
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
};

