import './css/style.css';
import * as Comp from './components.js';
import * as $ from './function.js';
import * as Data from './data.js';

import { createRoot } from 'react-dom/client';
import React from 'react';
import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toast } from '@capacitor/toast';

// import { LocalNotifications } from '@capacitor/local-notifications';
// import { BackgroundRunner } from '@capacitor/background-runner';

// API用URL
const url = 'https://aws16.intec-sol.co.jp/api';
// Indexed DB 設定
const dataBase = 'appDataBase';
const dbVersion = 1;
const stores = { storeSetting: 'name' };
const store = 'storeSetting';
const db = new $.AppDexie(dataBase,dbVersion,stores,store);
const setting = await db.step1(Data.firstData);



// version を DB から取得    
fetch(url+'/version',{method: 'POST'}).then((res)=>{return res.json()}).then((newVer)=>{
    // バージョンアップしていれば
    if($.arraySerch(setting,'version') < newVer){
        console.log('古い');
        // データを取得してindexDB書き換え
        fetch(url+'/setting',{method: 'POST'}).then(function(res){return res.json()}).then((data)=>{
            data.map((val)=>{
                if($.isJson($.arraySerch(data,val.name))){
                    val.value = JSON.parse(val['value']);
                }
            });
            // db.bulkPut(data);
            // Toast.show( {text: '更新されました。アプリを再起動して下さい。'} );
        });

    }else{
        console.log('最新');
    }
});



// React.Router
function ReactRootApps(){
    const [stateSetting, setSetting] = useState( setting ); 
    return(
        <BrowserRouter>
            {/* header */}
                <div id='header' className='position-sticky top-0 start-0 w-100 shadow-lg'>
                <meta id="csrf-token" content={$.arraySerch(stateSetting,'device')}></meta>
                    {/* HeaderLogo */}
                    <Comp.Logo $={$} setting={stateSetting} />
                    {/* sideMenu */}
                    <Comp.SideMenu $={$} setting={stateSetting} />
                </div>
            {/* main */}
                <div id='main' className='position-relative'>
                    <Routes>
                        <Route path='/' element={<Comp.Home $={$} setting={stateSetting} />} />
                        <Route path='/reg' element={<Comp.Regist />} />
                        <Route path='/qrcode' element={<Comp.QrCode $={$} />} />
                        {
                            $.arraySerch(stateSetting,'side_menu').map((val,index)=>{
                                return(
                                    <Route key={index} path={'/'+val.id} element={<Comp.Iflame url={val.url} />} />
                                )
                            })
                        }
                    </Routes>
                </div>
            {/* button */}
                <Comp.LowerMenu $={$} />
            {/* footer */}
                <Comp.Footer $={$} setting={stateSetting} />
        </BrowserRouter>
    )
}
const root = createRoot(document.getElementById('root'));
root.render(<ReactRootApps />);